// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-all-tsx": () => import("./../src/pages/all.tsx" /* webpackChunkName: "component---src-pages-all-tsx" */),
  "component---src-pages-default-js": () => import("./../src/pages/default.js" /* webpackChunkName: "component---src-pages-default-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-temp-index-tsx": () => import("./../src/pages/temp-index.tsx" /* webpackChunkName: "component---src-pages-temp-index-tsx" */),
  "component---src-templates-issue-tsx": () => import("./../src/templates/issue.tsx" /* webpackChunkName: "component---src-templates-issue-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

